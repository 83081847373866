import "./src/assets/css/bootstrap.min.css"
import "./src/styles/global.scss"
import "jquery/dist/jquery.min.js"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "slick-carousel/slick/slick.min.js"

export const onInitialClientRender = () => {
  setTimeout(function () {
    document.body.classList.add("page-loaded")
  }, 1000)
}
